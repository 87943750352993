import { ErrorResponse, LocateInventoryRequest, LocateInventoryResponse } from './data-contracts';
import { ContentType, HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class InventoryLocations<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * @description Find inventory locations with default item attributes such as quantity of 1 and C62 unit of measure.
	 *
	 * @tags Inventory Locations
	 * @name GetInventoryLocations
	 * @summary Locate inventory with default item attributes.
	 * @request GET:/v1/inventory-locations
	 * @response `200` `LocateInventoryResponse` The requested completed successfully.
	 * @response `400` `ErrorResponse` Bad Request - Request parameters are incorrect
	 * @response `401` `ErrorResponse` Not authenticated. The user session is not valid.
	 * @response `403` `ErrorResponse` The user is not authorized to perform the specified request.
	 * @response `500` `ErrorResponse` Internal server error. Additional details will be contained on the server logs.
	 */
	getInventoryLocations = (
		query: {
			/**
			 * The store identifier.
			 * @example "Ruby"
			 */
			store: string;
			/**
			 * The fulfillment center code(s).
			 * @example "R00B2C"
			 */
			fulfillmentCenter: string[];
			/**
			 * The part number.
			 * @example "LR-FNTR-CO-0001-0001"
			 */
			partNumber: string[];
			/**
			 * @format int32
			 * @default 0
			 */
			offset?: number;
			/**
			 * The limit used to specify number of fulfillment centers for each page.
			 * @format int32
			 * @default 10
			 * @example 10
			 */
			limit?: number;
			/**
			 * The sortBy used to sort the fulfillment centers. For example fulfillmentCenterName.
			 * @default "fulfillmentCenterId"
			 * @example "fulfillmentCenterName"
			 */
			sortBy?: string;
			/**
			 * The sortDir used to sort fulfillment centers.
			 * @default "ASC"
			 * @example "ASC"
			 */
			sortDir?: string;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['getInventoryLocations'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/v1/inventory-locations`,
				params: _params,
				query: query,
				methodName: 'getInventoryLocations',
			};
			logger.trace(traceData);
		}
		return this.http.request<LocateInventoryResponse, ErrorResponse>({
			path: `/v1/inventory-locations`,
			method: 'GET',
			query: query,
			format: params.format ?? 'json',
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
	/**
	 * @description Find inventory locations with request body to take additional item parameters such as quantity and unit of measure.
	 *
	 * @tags Inventory Locations
	 * @name GetInventoryLocationsWithRequestBody
	 * @summary Locate inventory with additional item attributes.
	 * @request POST:/v1/inventory-locations
	 * @response `200` `LocateInventoryResponse` The requested completed successfully.
	 * @response `400` `ErrorResponse` Bad Request - Request parameters are incorrect
	 * @response `401` `ErrorResponse` Not authenticated. The user session is not valid.
	 * @response `403` `ErrorResponse` The user is not authorized to perform the specified request.
	 * @response `500` `ErrorResponse` Internal server error. Additional details will be contained on the server logs.
	 */
	getInventoryLocationsWithRequestBody = (
		query: {
			/**
			 * The store identifier.
			 * @example "Ruby"
			 */
			store: string;
			/**
			 * The fulfillment center code(s).
			 * @example "R00B2C"
			 */
			fulfillmentCenter: string[];
			/**
			 * @format int32
			 * @default 0
			 */
			offset?: number;
			/**
			 * The limit used to specify number of fulfillment centers for each page.
			 * @format int32
			 * @default 10
			 * @example 10
			 */
			limit?: number;
			/**
			 * The sortBy used to sort the fulfillment centers. For example fulfillmentCenterName.
			 * @default "fulfillmentCenterId"
			 * @example "fulfillmentCenterName"
			 */
			sortBy?: string;
			/**
			 * The sortDir used to sort fulfillment centers.
			 * @default "ASC"
			 * @example "ASC"
			 */
			sortDir?: string;
		},
		data: LocateInventoryRequest,
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['getInventoryLocationsWithRequestBody'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/v1/inventory-locations`,
				params: _params,
				query: query,
				body: data,
				methodName: 'getInventoryLocationsWithRequestBody',
			};
			logger.trace(traceData);
		}
		return this.http.request<LocateInventoryResponse, ErrorResponse>({
			path: `/v1/inventory-locations`,
			method: 'POST',
			query: query,
			body: data,
			type: params.type ?? ContentType.Json,
			format: params.format ?? 'json',
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}
