import { ArraySchema, ErrorResponse, InventoryResponse } from './data-contracts';
import { HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class ItemInventory<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * @description This API fetches the inventory details that match the partnumber, fulfillmentCenter, status, store.
	 *
	 * @tags Item Inventory
	 * @name GetInventoryItemAvailability
	 * @summary Fetch inventory by part number, fulfillmentCenter, status, store
	 * @request GET:/v1/item-inventories
	 * @response `200` `InventoryResponse` The requested completed successfully.
	 * @response `400` `ErrorResponse` Bad Request - Request parameters are incorrect
	 * @response `401` `ErrorResponse` Not authenticated. The user session is not valid.
	 * @response `403` `ErrorResponse` The user is not authorized to perform the specified request.
	 * @response `404` `ErrorResponse` The specified resource could not be found.
	 * @response `500` `ErrorResponse` Internal server error. Additional details will be contained on the server logs.
	 */
	getInventoryItemAvailability = (
		query: {
			/**
			 * The partNumber used to fetch the inventory details.It is matched to the partnumber.
			 * @example "LR-FNTR-CO-0002-0002"
			 */
			partNumber: string | ArraySchema;
			/**
			 * The store used to fetch the inventory details.It is matched to the store name.
			 * @example "Ruby"
			 */
			store: string;
			/**
			 * The fulfillment center used to fetch the inventory details. It is matched to the fulfillment center.
			 * @example "MM2050"
			 */
			fulfillmentCenter?: string | ArraySchema;
			/**
			 * The status used to fetch the inventory details.It is matched to the status inventories.
			 * @example "available"
			 */
			status?: string | ArraySchema;
			/**
			 * The offset specify page number.
			 * @format int32
			 * @default 0
			 * @example 1
			 */
			offset?: number;
			/**
			 * The limit used to specify number of inventory details records for each page.
			 * @format int32
			 * @default 10
			 * @example 10
			 */
			limit?: number;
			/**
			 * The sortBy used to sort the inventory details. For example partNumber.
			 * @default "partNumber"
			 * @example "partNumber"
			 */
			sortBy?: string;
			/**
			 * The sortDir used to sort inventory details.
			 * @default "ASC"
			 * @example "ASC"
			 */
			sortDir?: string;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['getInventoryItemAvailability'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/v1/item-inventories`,
				params: _params,
				query: query,
				methodName: 'getInventoryItemAvailability',
			};
			logger.trace(traceData);
		}
		return this.http.request<InventoryResponse, ErrorResponse>({
			path: `/v1/item-inventories`,
			method: 'GET',
			query: query,
			format: params.format ?? 'json',
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
	/**
	 * @description This API fetches the inventory details that match the partnumber.
	 *
	 * @tags Item Inventory
	 * @name GetInventoryItemAvailabilityById
	 * @summary Fetch inventory by part number
	 * @request GET:/v1/item-inventories/{partNumber}
	 * @response `200` `InventoryResponse` The requested completed successfully.
	 * @response `400` `ErrorResponse` Bad Request - Request parameters are incorrect
	 * @response `401` `ErrorResponse` Not authenticated. The user session is not valid.
	 * @response `403` `ErrorResponse` The user is not authorized to perform the specified request.
	 * @response `404` `ErrorResponse` The specified resource could not be found.
	 * @response `500` `ErrorResponse` Internal server error. Additional details will be contained on the server logs.
	 */
	getInventoryItemAvailabilityById = (
		partNumber: string,
		query: {
			/**
			 * The store used to fetch the inventory details.It is matched to the store name.
			 * @example "Ruby"
			 */
			store: string;
			/**
			 * The fulfillment center used to fetch the inventory details. It is matched to the fulfillment center.
			 * @example "MM2050"
			 */
			fulfillmentCenter?: string | ArraySchema;
			/**
			 * The status used to fetch the inventory details.It is matched to the status inventories.
			 * @example "available"
			 */
			status?: string | ArraySchema;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['getInventoryItemAvailabilityById'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/v1/item-inventories/${partNumber}`,
				params: _params,
				query: query,
				methodName: 'getInventoryItemAvailabilityById',
			};
			logger.trace(traceData);
		}
		return this.http.request<InventoryResponse, ErrorResponse>({
			path: `/v1/item-inventories/${partNumber}`,
			method: 'GET',
			query: query,
			format: params.format ?? 'json',
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}
