import { keyBy } from 'lodash';
import { HttpClient } from './http-client';
import { InventoryLocations } from './InventoryLocations';
import { ItemInventory } from './ItemInventory';

const traceDetails = process.env.TRACE_DETAILS?.trim() ? keyBy(process.env.TRACE_DETAILS.split(',').map((f) => f.trim()).filter(Boolean)) : undefined;
const publicClient = new HttpClient({
	baseUrl: process.env.NODE_ENV === 'production' ? '/inventory/api' : '/api/inventory',
	isPublic: true,
	traceDetails,
});
const privateClient = new HttpClient({
	baseUrl: (process.env.USE_MOCK === 'true' ? 'http://localhost:' + process.env.MOCK_HOST_PORT : process.env.INVENTORY_ORIGIN as string) + '/inventory/api',
	traceDetails,
});

export const inventoryPbcInventoryLocations = (pub: boolean) => new InventoryLocations(pub ? publicClient : privateClient);
export const inventoryPbcItemInventory = (pub: boolean) => new ItemInventory(pub ? publicClient : privateClient);
